interface ChatHeaderProps {
  children: React.ReactNode
}

export const ChatHeader = ({ children }: ChatHeaderProps) => {
  return (
    <div className="flex w-full items-center justify-between bg-gradient-to-r from-primary-start/10 to-primary-stop/10 p-m">
      {children}
    </div>
  )
}
